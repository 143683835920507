<template>
  <section class="youtube-section">
    <div class="youtube-header">
      <!-- <img class="youtube-image" src="@/assets/images/logo.webp" alt="Enclave Executive Command"> -->
      <div class="youtube-content">
        <h2 class="section-title">Enclave76 on <span style="color: red;">YouTube</span></h2>
        <p class="section-subtitle">Tune in to the voice of true America. Our channel showcases the Enclave's tireless efforts to restore order and purity to our great nation. Subscribe now and be part of the rebirth of the American dream.</p>
        <a :href="youtubeChannelUrl" target="_blank" class="btn btn-primary fw-bold" @click="trackEvent('Subscribe on YouTube')">Subscribe on YouTube</a>
      </div>
    </div>
    <div class="container">
      <div class="video-container" v-if="mainVideo">
        <div class="main-video">
          <div class="video-wrapper">
            <iframe 
              :src="'https://www.youtube.com/embed/' + mainVideo.id" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen>
            </iframe>
          </div>
          <div class="video-info">
            <h1 class="video-title">{{ mainVideo.snippet.title }}</h1>
            <div class="video-meta">
              <p>{{ formatViews(mainVideo.statistics.viewCount) }} views • {{ formatDate(mainVideo.snippet.publishedAt) }}</p>
              <div class="video-actions">
                <button class="btn btn-light action-btn like" @click="openYouTubeChannel">
                  <font-awesome-icon :icon="['fas', 'thumbs-up']" />
                  {{ formatCount(mainVideo.statistics.likeCount) }}
                </button>
                <button class="btn btn-light action-btn dislike" @click="openYouTubeChannel">
                  <font-awesome-icon :icon="['fas', 'thumbs-down']" />
                </button>
                <button class="btn btn-light action-btn share" @click="openYouTubeChannel">
                  <font-awesome-icon :icon="['fas', 'share']" />
                  Share
                </button>
                <button class="btn btn-light action-btn save" @click="openYouTubeChannel">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                  Save
                </button>
                <button class="btn btn-light action-btn more" @click="openYouTubeChannel">
                  <font-awesome-icon :icon="['fas', 'ellipsis-h']" />
                </button>
              </div>
            </div>
          </div>
          <div class="channel-info" v-if="channelInfo">
            <img 
              :src="logoSrc" 
              alt="Channel avatar" 
              class="channel-avatar"
            >
            <div class="channel-details">
              <h3 class="channel-name">{{ channelInfo.title }}</h3>
              <p class="subscriber-count">{{ formatCount(channelInfo.statistics.subscriberCount) }} subscribers</p>
            </div>
            <button class="subscribe-btn" @click="openYouTubeChannel">Subscribe</button>
          </div>
          <div class="video-description" :class="{ 'expanded': isDescriptionExpanded }">
            <p v-html="formattedDescription"></p>
          </div>
          <button @click="toggleDescription" class="show-more-btn">
            {{ isDescriptionExpanded ? 'Show less' : 'Show more' }}
          </button>
        </div>
        <div class="video-list">
          <div v-for="video in otherVideos" :key="video.id" class="video-item" @click="setMainVideo(video)">
            <img :src="video.snippet.thumbnails.medium.url" :alt="video.snippet.title" class="thumbnail">
            <div class="video-item-info">
              <h4>{{ video.snippet.title }}</h4>
              <p class="channel-name">{{ channelInfo.title }}</p>
              <p class="video-stats">{{ formatViews(video.statistics.viewCount) }} views • {{ formatTimeAgo(video.snippet.publishedAt) }}</p>
            </div>
          </div>
        </div>
        <button 
          v-if="otherVideos.length > 5" 
          @click="toggleVideoList" 
          class="show-more-videos d-md-none mb-4 fw-bold text-primary text-uppercase">
          {{ isVideoListExpanded ? 'Show Less' : 'Show More' }}
        </button>
      </div>
      <div v-else-if="error" class="error">
        <p>{{ error }}</p>
      </div>
      <div v-else class="loading">
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> Loading videos...
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import logoImage from '@/assets/images/logo.webp';

export default {
  name: 'YoutubeSection',
  data() {
    return {
      channelInfo: null,
      videos: [],
      mainVideo: null,
      error: null,
      isDescriptionExpanded: false,
      logoSrc: logoImage,
      isVideoListExpanded: false,
    }
  },
  computed: {
    otherVideos() {
      return this.videos.filter(video => video.id !== this.mainVideo?.id);
    },
    formattedDescription() {
      if (!this.mainVideo?.snippet.description) return '';
      return this.mainVideo.snippet.description
        .replace(/\n/g, '<br>')
        .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
    },
    youtubeChannelUrl() {
      return this.channelInfo && this.channelInfo.customUrl
        ? `https://www.youtube.com/${this.channelInfo.customUrl}`
        : 'https://www.youtube.com/';
    },
  },
  mounted() {
    this.fetchYoutubeData();
  },
  methods: {
    async fetchYoutubeData() {
      try {
        const response = await axios.get('/youtubeData.json');
        if (!response.data || !response.data.channelInfo || !Array.isArray(response.data.videos)) {
          throw new Error('Invalid data structure in youtubeData.json');
        }
        this.channelInfo = response.data.channelInfo;
        this.videos = response.data.videos;
        if (this.videos.length === 0) {
          throw new Error('No videos found');
        }
        this.mainVideo = this.videos[0];
      } catch (error) {
        console.error('Error fetching YouTube data:', error);
        this.error = `Failed to load YouTube data: ${error.message}`;
        // Tilføj en metrik for fejl
        if (window.gtag) {
          window.gtag('event', 'error', {
            'event_category': 'YouTube Data',
            'event_label': error.message
          });
        }
      }
    },
    formatViews(viewCount) {
      return viewCount === '0' ? 'N/A' : new Intl.NumberFormat('en-US', { notation: 'compact' }).format(viewCount);
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    },
    formatCount(count) {
      return count === '0' ? 'N/A' : new Intl.NumberFormat('en-US', { notation: 'compact' }).format(count);
    },
    setMainVideo(video) {
      this.mainVideo = video;
    },
    formatTimeAgo(dateString) {
      const now = new Date();
      const date = new Date(dateString);
      const seconds = Math.floor((now - date) / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      
      if (seconds < 60) return `${seconds} seconds ago`;
      if (minutes < 60) return `${minutes} minutes ago`;
      if (hours < 24) return `${hours} hours ago`;
      if (days < 7) return `${days} days ago`;
      const weeks = Math.floor(days / 7);
      if (weeks < 4) return `${weeks} weeks ago`;
      const months = Math.floor(days / 30);
      if (months < 12) return `${months} months ago`;
      const years = Math.floor(days / 365);
      return `${years} years ago`;
    },
    openYouTubeChannel() {
      if (this.channelInfo && this.channelInfo.customUrl) {
        window.open(`https://www.youtube.com/${this.channelInfo.customUrl}`, '_blank');
      } else {
        console.error('Channel URL not available');
      }
    },
    toggleDescription() {
      this.isDescriptionExpanded = !this.isDescriptionExpanded;
    },
    trackEvent(action) {
      if (window.gtag) {
        window.gtag('event', action, {
          'event_category': 'Button',
          'event_label': 'YouTube Section'
        });
      } else {
        console.warn('Google Analytics not loaded');
      }
    },
    toggleVideoList() {
      this.isVideoListExpanded = !this.isVideoListExpanded;
      const videoList = document.querySelector('.video-list');
      if (videoList) {
        videoList.classList.toggle('expanded');
      }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');


.section-subtitle {
  font-family: 'Agency Regular', sans-serif !important;
  color: #f6f6f6;
}

.youtube-section {
  background-color: #f9f9f9;
}

.youtube-header {
  height: 50vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  background-color: #000;
  overflow: hidden;
  background-image: url('@/assets/images/youtube_background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 24px;
}

.youtube-content {
  max-width: 50%;
  color: #f6f6f6!important;
  position: relative;
  z-index: 1;
  text-align: right;
  margin-left: auto; /* Skubber indholdet til højre */
}

.youtube-image {
  width: 125px;
  height: 125px;
  margin-right: auto; /* Skubber billedet til venstre */
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.section-subtitle {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 2rem;
}

.btn-primary {
  background-color: #cc0000;
  border-color: #cc0000;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  text-decoration: none;
  display: inline-block;
}

.btn-primary:hover {
  background-color: #990000;
  border-color: #990000;
}

@media (max-width: 768px) {
  .youtube-header {
    height: auto;
    min-height: 40vh;
    padding: 40px 20px;
  }

  .youtube-content {
    max-width: 100%;
    text-align: center;
    padding: 0 15px;
  }

  .video-container {
    padding: 0;
  }

  .video-meta {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .video-actions {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .action-btn {
    font-size: 12px;
    padding: 4px 8px;
  }

  .channel-info {
    flex-wrap: wrap;
    gap: 10px;
  }

  .subscribe-btn {
    width: 100%;
    margin-top: 10px;
  }
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 24px;
}

.video-container {
  display: flex;
  gap: 24px;
  margin-top: 24px;
}

.main-video {
  flex: 1;
  max-width: 854px; /* Standard YouTube video width */
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-info {
  margin-top: 12px;
}

.video-title {
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 1.4;
  font-style: normal;
}

.video-meta {
  display: flex;
  justify-content: space-between;
  align-items: start;
  color: #606060;
  font-size: 14px;
  margin-bottom: 12px;
}

.video-actions {
  display: flex;
  gap: 8px;
}

.action-btn {
  background: #f2f2f2;
  border: none;
  border-radius: 18px;
  padding: 6px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #030303;
  font-weight: 500;
}

.channel-info {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.channel-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 12px;
}

.channel-name {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #030303;
}

.subscriber-count {
  font-size: 12px;
  color: #606060;
  margin: 2px 0 0;
}

.subscribe-btn {
  background-color: #cc0000;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 18px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-left: auto;
}

.video-description {
  margin-top: 16px;
  font-size: 14px;
  color: #030303;
  white-space: pre-wrap;
  word-break: break-word;
  max-height: 80px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.video-description.expanded {
  max-height: none;
}

.show-more-btn {
  background: none;
  border: none;
  color: #065fd4;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 0;
  font-size: 14px;
}

.show-more-btn:hover {
  text-decoration: underline;
}

.video-list {
  flex: 0 0 402px;
  overflow-y: auto;
  max-height: 600px;
  padding-right: 24px;
}

.video-item {
  display: flex;
  margin-bottom: 8px;
  cursor: pointer;
}

.thumbnail {
  width: 168px;
  height: 94px;
  object-fit: cover;
  margin-right: 8px;
  border-radius: 8px;
}

.video-item-info h4 {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 4px 0;
  color: #030303;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4;
}

.video-item-info .channel-name,
.video-item-info .video-stats {
  font-size: 12px;
  color: #606060;
  margin: 0;
}

@media (max-width: 1024px) {
  .video-container {
    flex-direction: column;
  }

  .video-list {
    max-height: none;
    flex: 1;
    padding-right: 0;
  }

  .main-video {
    max-width: 100%;
  }
}

.action-btn:hover, .subscribe-btn:hover {
  opacity: 0.8;
  transition: opacity 0.2s;
}

.video-list::-webkit-scrollbar {
  width: 8px;
}

.video-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.video-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.video-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.error {
  color: #cc0000;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  background-color: #ffebee;
  padding: 16px;
  border-radius: 4px;
}

.action-btn {
  padding: 6px 12px;
  margin-right: 8px;
}

.action-btn .svg-inline--fa {
  margin-right: 4px;
}

@media (max-width: 768px) {
  .video-list {
    max-height: 400px;
    overflow-y: hidden;
    position: relative;
  }

  .video-list.expanded {
    max-height: none;
  }

  .video-item:nth-child(n+6) {
    display: none;
  }

  .video-list.expanded .video-item:nth-child(n+6) {
    display: flex;
  }

  .show-more-videos {
    width: 100%;
    text-align: center;
    padding: 10px;
    background: rgba(0,0,0,0.05);
    border: none;
    margin-top: 10px;
    cursor: pointer;
    color: #065fd4;
  }
}
</style>