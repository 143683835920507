<template>
  <section class="hero">
    <!-- Loading State -->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>

    <!-- Optimized Video Background -->
    <video 
      ref="videoPlayer"
      class="hero-background" 
      autoplay 
      loop 
      playsinline
      :muted="isMuted"
      preload="auto"
      poster="../assets/images/header_background_nov.jpg"
      @canplaythrough="handleVideoLoad"
      @suspend="handleVideoLoad"
      @stalled="handleLoadingError"
    >
      <!-- Prioritized video sources from most efficient to fallback -->
      <source 
        src="../assets/video/video_header.webm" 
        type="video/webm; codecs=vp9"
      >
      <source 
        src="../assets/video/video_header.mp4" 
        type="video/mp4; codecs=hevc,mp4a.40.2"
      >
    </video>

    <!-- Main Content -->
    <div 
      class="hero-content" 
      :class="{ 'content-visible': !isLoading }"
      v-show="!isLoading"
    >
      <h1 class="hero-title">Enclave76: America's Best Hope</h1>
      <p class="hero-subtitle">Citizens of the wasteland, hear the call of your true government. The Enclave stands ready to reclaim our great nation and restore the American dream.</p>
      <p class="hero-subtitle fs-3 fw-bold">Your patriotic duty awaits!</p>
      <a 
        href="https://discord.gg/gQgtrRKefh" 
        target="_blank" 
        rel="noopener"
        class="btn btn-primary btn-lg fw-bold" 
        @click="trackEvent('Enlist and Serve')"
      >
        Enlist and Serve
      </a>
    </div>

    <!-- Logo with lazy loading -->
    <img 
      class="hero-image" 
      src="../assets/images/logo.webp" 
      alt="Enclave Executive Command"
      loading="lazy"
      :class="{ 'content-visible': !isLoading }"
      v-show="!isLoading"
    >

    <!-- Sound Control -->
    <button 
      @click="toggleMute" 
      class="unmute-btn"
      :class="{ 'content-visible': !isLoading }"
      v-show="!isLoading"
    >
      <span class="icon">{{ isMuted ? '🔊' : '🔇' }}</span>
      <span class="text">{{ isMuted ? 'Enable Sound' : 'Disable Sound' }}</span>
    </button>
  </section>
</template>

<script>
export default {
  name: 'AppHeroSection',
  data() {
    return {
      isMuted: true,
      isLoading: true,
      loadTimeout: null,
      retryCount: 0
    }
  },
  mounted() {
    this.initializeVideo();
    
    // Fallback timer
    this.loadTimeout = setTimeout(() => {
      if (this.isLoading) {
        this.handleVideoLoad();
      }
    }, 2000);
  },
  beforeUnmount() {
    if (this.loadTimeout) {
      clearTimeout(this.loadTimeout);
    }
    this.cleanupVideo();
  },
  methods: {
    initializeVideo() {
      const video = this.$refs.videoPlayer;
      if (!video) return;

      // Optimize video loading
      video.preload = 'auto';
      video.load();

      // Set initial playback position
      if ('fastSeek' in video) {
        video.fastSeek(0);
      } else {
        video.currentTime = 0;
      }

      // Add error handling
      video.onerror = this.handleLoadingError;
    },
    handleVideoLoad() {
      if (this.loadTimeout) {
        clearTimeout(this.loadTimeout);
      }
      this.isLoading = false;
    },
    handleLoadingError(error) {
      console.error('Video loading error:', error);
      if (this.retryCount < 2) {
        this.retryCount++;
        this.initializeVideo();
      } else {
        this.handleVideoLoad(); // Show content anyway
      }
    },
    cleanupVideo() {
      const video = this.$refs.videoPlayer;
      if (video) {
        video.pause();
        video.removeAttribute('src');
        video.load();
      }
    },
    toggleMute() {
      this.isMuted = !this.isMuted;
      const video = this.$refs.videoPlayer;
      if (video) {
        video.volume = this.isMuted ? 0 : 0.5;
        video.muted = this.isMuted;
      }
      this.trackEvent(this.isMuted ? 'Mute Video' : 'Unmute Video');
    },
    trackEvent(action) {
      try {
        if (window.gtag) {
          window.gtag('event', action, {
            'event_category': 'Button',
            'event_label': 'Hero Section'
          });
        }
      } catch (error) {
        console.warn('Analytics error:', error);
      }
    }
  }
}
</script>

<style scoped>
.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  overflow: hidden;
  background-color: #000;
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url('../assets/images/header_background_nov.jpg');
  background-size: cover;
  background-position: center;
}

.container{
  margin: 0 5%!important;
  padding: 0!important;
}

/* Loading overlay */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  will-change: transform;
  transform: translateZ(0);
}

.content-visible {
  animation: fadeIn 0.3s ease-out forwards;
  will-change: opacity;
  backface-visibility: hidden;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.hero-content {
  max-width: 50%;
  color: #f6f6f6;
  position: relative;
  z-index: 1;
}

.hero-title {
  font-size: 3rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-subtitle {
  font-size: 1.6rem;
  font-weight: 300;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.hero-image {
  position: absolute;
  width: 125px;
  height: 125px;
  bottom: 5%;
  right: 5%;
  z-index: 1;
}

.btn, .btn-primary {
  background-color: rgba(46, 87, 140, 1)!important;
  transition: transform 0.2s ease-in-out;
}

.btn:hover {
  transform: scale(1.05);
}

.unmute-btn {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2;
  padding: 12px 20px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.unmute-btn:hover {
  background: rgba(0, 0, 0, 0.8);
}

.unmute-btn .icon {
  font-size: 1.2em;
}

.unmute-btn .text {
  font-size: 0.9em;
}

/* Media Queries */
@media (max-width: 768px) {
  .hero {
    height: auto;
    min-height: 100vh;
    padding: 60px 5%;
    flex-direction: column;
    text-align: center;
    background-position: center;
  }

  .hero-content {
    margin-top: 100px;
    max-width: 100%;
  }

  .hero-title {
    font-size: 1.8rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .hero-image {
    position: static;
    width: 100px;
    height: 100px;
    margin-top: 2rem;
  }

  .unmute-btn {
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 16px;
  }

  .hero-background {
    object-position: center;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .hero-content {
    color: #ffffff;
  }
}

/* Reduced motion */
@media (prefers-reduced-motion: reduce) {
  .content-visible {
    animation: none;
    opacity: 1;
    transform: none;
  }
}
</style>
