<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-content">
        <div class="footer-section">
          <h3>The Enclave76's Mission</h3>
          <p>We are the last bastion of true American values, dedicated to restoring our great nation to its former glory. Join us in our noble cause.</p>
          <img class="hero-image" src="../assets/images/logo.webp" alt="Enclave: America Reborn">
        </div>
        <div class="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="#about">About Us</a></li>
            <li><a href="#community">Community</a></li>
          </ul>
            <div id="donate-button" @click="trackEvent('Donate')"></div>
        </div>
        <div class="footer-section">
          <h3>Follow Us</h3>
          <div class="social-icons">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" class="social-link">
              <font-awesome-icon :icon="['fab', 'facebook']" />
              <span>Facebook</span>
            </a>
            <a href="https://www.instagram.com/enclave_executive_command/" target="_blank" rel="noopener noreferrer" class="social-link">
              <font-awesome-icon :icon="['fab', 'instagram']" />
              <span>Instagram</span>
            </a>
            <a href="https://www.youtube.com/@enclave-executive-command" target="_blank" rel="noopener noreferrer" class="social-link">
              <font-awesome-icon :icon="['fab', 'youtube']" />
              <span>YouTube</span>
            </a>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <p>&copy; 2023 Fallout 76 Enclave Community. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
/* global PayPal */
export default {
  name: 'AppFooterSection',
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://www.paypalobjects.com/donate/sdk/donate-sdk.js'
    script.charset = 'UTF-8'
    document.head.appendChild(script)

    script.onload = () => {
      PayPal.Donation.Button({
        env:'production',
        hosted_button_id:'83CQW8EV4H848',
        image: {
          src:'https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif',
          alt:'Donate with PayPal button',
          title:'PayPal - The safer, easier way to pay online!',
        }
      }).render('#donate-button');
    }
  },
  methods: {
    trackEvent(action) {
      // eslint-disable-next-line no-undef
      gtag('event', action, {
        'event_category': 'Button',
        'event_label': 'Footer Section'
      });
    }
  }
}
</script>

<style scoped>
.footer {
  background-color: #1d1d1f;
  color: #f5f5f7;
  padding: 60px 0 30px;
  width: 100%;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}
.footer-content {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.footer-section {
  flex: 1;
  text-align: center;
}

.footer-section h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.footer-section p, .footer-section ul {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section a {
  color: #a0a0a0;
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.footer-section a:hover {
  color: #f6f6f6;
  text-decoration: underline;
}

.social-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.social-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.25rem;
  color: #a0a0a0;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: #f6f6f6;
}

.footer-bottom {
  margin-top: 2rem;
  text-align: center;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.8rem;
  color: #86868b;
}

.hero-image {
  width: 75px;
  height: 75px;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    gap: 2rem;
  }

  .footer-section {
    text-align: center;
  }

  .social-icons {
    flex-direction: row;
    justify-content: center;
    gap: 1.5rem;
  }

  .footer-bottom {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }
}
</style>
