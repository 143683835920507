<template>
  <section id="about" class="about section">
    <div class="container p-2">
      <div class="row align-items-start justify-content-between mb-4">
        <div class="col-auto d-none d-md-block">
          <img src="../assets/images/presidential-seal.webp" alt="Presidential Seal" class="presidential-seal">
        </div>
        <div class="col text-center">
          <h2 class="section-title">Enclave76: Defenders of America's True Legacy</h2>
          <p class="section-subtitle">Ensuring America's Future, One Citizen at a Time</p>
        </div>
        <div class="col-auto align-self-center">
          <img src="../assets/images/presidential-seal.webp" alt="Presidential Seal" class="presidential-seal">
        </div>
      </div>
      <div class="about-content">
        <div class="about-text">
          <p class="fw-bold">Presidential Seal</p>
          <p>Greetings, Patriots. This is your President, John Henry Eden.
The Enclave is more than a faction—it's a vision for a reborn America. We stand as the last defenders of true American ideals, a force unshaken by the storms of war, unwavering against the mutations that now threaten our homeland. Our purpose is clear: to restore America to its former strength, free from the taint of radiation and the corruption of a broken world.
With state-of-the-art technology, advanced training, and the courage of every dedicated citizen, we're reclaiming this great land—block by block, street by street, until America is strong, pure, and prosperous once again.</p>
          <p><a href="https://discord.gg/gQgtrRKefh" target="_blank" class="fw-bold">Join us, and together we'll cleanse the wasteland.</a> Help us build a secure future for the children of tomorrow. This isn't just a mission—it's a movement to rebuild America for those who share our vision. Stand with the Enclave today, and remember: the Enclave is your government, and your government is you.</p>
          <p class="pt-4 fw-bold">Support Our Mission</p>
          <p>Your contributions allow us to expand our reach, protect our communities, and provide the advanced resources necessary to take back our land. Every donation strengthens our resolve. Patriots, let us rise together—because rebuilding America takes every one of us.</p>
        </div>
      </div>
    </div>
    <!-- <div class="discord-header">
      <div class="discord-content">
        <h2 class="section-title">Enclave76 on <span style="color:#3498DB;">Discord</span></h2>
        <p class="section-subtitle">Tune in to the voice of true America. Our channel showcases the Enclave's tireless efforts to restore order and purity to our great nation. Subscribe now and be part of the rebirth of the American dream.</p>
        <a :href="discordChannelUrl" target="_blank" class="btn btn-primary fw-bold" @click="trackEvent('Join us on Discord')">Join us on Discord</a>
      </div>
    </div> -->
  </section>
</template>

<script>
import logoImage from '@/assets/images/logo.webp';

export default {
  name: 'AboutSection',
  data() {
    return {
      logoSrc: logoImage,
    }
  },
  computed: {
    discordChannelUrl() {
      return 'https://discord.gg/gQgtrRKefh'; // Adjust this as needed
    },
  },
  methods: {
    trackEvent(action) {
      if (window.gtag) {
        window.gtag('event', action, {
          'event_category': 'Button',
          'event_label': 'Discord Section'
        });
      } else {
        console.warn('Google Analytics not loaded');
      }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

#about {
  background: linear-gradient(145deg, #1b2735, #101820); /* Dark MODUS-like gradient */
  color: #fff; /* Neon cyan text color */
  box-shadow: 0px 0px 30px 10px rgba(0, 255, 204, 0.3); /* Glow effect */
  padding: 40px 0;
}

#about .container {
  padding: 24px 0;
}

.section-subtitle {
  font-family: 'Agency Regular', sans-serif !important;
  color: #fff; /* Match text color to neon */
}

.about-content {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 4rem;
}

.about-text p {
  color: #f6f6f6; /* Ensure readability on dark background */
  font-size: 1.3rem;
  text-align: left;
}

.about-text a {
  color: #00ffcc; /* Keep link color in line with the neon theme */
  text-decoration: underline;
}

.about-text a:hover {
  color: #ffcc00; /* Hover state for better user feedback */
  text-shadow: 0px 0px 5px rgba(255, 204, 0, 0.8);
}

.discord-header {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  background-color: #000;
  background-image: url('@/assets/images/youtube_background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.discord-content {
  max-width: 50%;
  color: #f6f6f6;
  position: relative;
  z-index: 1;
  text-align: right;
  margin-left: auto;
}

.btn-primary {
  background-color: #00ffcc;
  border-color: #00ffcc;
  color: #000;
  padding: 10px 20px;
  font-size: 1rem;
  text-decoration: none;
}

.btn-primary:hover {
  background-color: #ffcc00;
  border-color: #ffcc00;
}

.presidential-seal {
  width: 150px;
  height: 150px;
  object-fit: contain;
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
}

/* Update your container style */
.container {
  max-width: 1200px; /* Match the header-container max-width */
  margin: 0 auto;
  padding: 24px 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .presidential-seal {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    display: block;
  }
  
  .row {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;
  }
  
  .about-text p {
    font-size: 1.1rem;
    padding: 0 15px;
  }
  
  .section-title {
    font-size: 2rem;
  }
  
  .section-subtitle {
    font-size: 1.2rem;
  }
}
</style>
